<template>
  <v-container fill-height>
    <v-layout column fill-height wrap>
      <v-container style="width: 90%;  max-height=90vh;" class="mx-auto pa-1">
        <v-row style="width: 90vw;  max-height:90vh;" class="pa-0">
          <v-card-title class="font-weight-bold">
            {{ $t('title.contractor.tos') }}
          </v-card-title>
        </v-row>
        <v-row>
          <v-container
            id="scroll-target"
            class="overflow-y-auto mx-auto"
            style="max-height: 30vh; width: 90vw;  border: #ddd 3px solid; "
            wrap
          >
            <v-row
              class="mx-0"
              v-scroll:#scroll-target="onScroll"
              align="center"
              justify="center"
              style="max-width: 100%;"
            >
              <TosTemplate />
            </v-row>
          </v-container>
        </v-row>
        <v-row>
          <v-card-title class="font-weight-bold">{{
            $t(`label.procedureFlow.title`)
          }}</v-card-title>
        </v-row>
        <!-- お手続きの流れ -->
        <table class="ma-0" style="width: 100%; border-spacing: 5px;">
          <tbody>
            <tr v-for="item in stepper" :key="item.step">
              <td
                class="blue darken-2 rounded-0 white--text font-weight-bold text-center px-4"
                style="width:60px; height:34px;"
              >
                {{ item.step }}
              </td>
              <td
                class="pl-3 font-weight-bold"
                style="border: solid 1px #cccccc;"
              >
                {{ item.text }}
              </td>
            </tr>
          </tbody>
        </table>

        <v-row class="my-12">
          <v-btn
            class="font-weight-black mx-auto"
            align="center"
            @click="goNextPage"
            width="70vw"
            max-width="350px"
            color="next"
            style="font-size:20px"
            dark
            rounded
            >{{ $t('button.confirmed') }}</v-btn
          >
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import TosTemplate from '@/pages/contractor/templates/TosTemplate';
import { stepList } from '@/lib/const';
import {
  getRequiredUploadingDocs,
  getAgencyInfo,
} from '@/apis/contractor/contracts';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Tos',
  components: {
    TosTemplate,
  },
  data() {
    return {
      offsetTop: 0,
      stepper: [],
    };
  },
  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    goNextPage() {
      if (!this.pendingStep()) this.setPendingStep(1);
      this.$router.push({
        path: '/base_info',
      });
    },
    ...mapGetters('contract', ['pendingStep']),
    ...mapActions('contract', [
      'setNeedUpload',
      'setAgencyName',
      'setAgencyPhoneNumber',
      'setPendingStep',
    ]),
  },
  async mounted() {
    const response = await getRequiredUploadingDocs();
    if (response instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    let needUpload = false;
    if (response) {
      response.forEach(element => {
        if (element.message && element.message.length > 0) {
          needUpload = true;
          return;
        }
      });
      this.setNeedUpload(needUpload);
      this.stepper = needUpload ? stepList.upload : stepList.nonUpload;
    }
    const agencyInfo = await getAgencyInfo();
    if (agencyInfo instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (agencyInfo) {
      this.setAgencyName(agencyInfo.agencyName);
      this.setAgencyPhoneNumber(agencyInfo.agencyPhoneNumber);
    }
  },
};
</script>

<style>
#stepper > .v-image__image--contain {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
